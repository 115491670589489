import { LinearGradient } from "expo-linear-gradient";
import { StatusBar } from 'expo-status-bar';
import { Linking, StyleSheet, Text, View } from 'react-native';

function Link({text, to}) {
  return (
    <Text style={{color: '#0F2027', textDecorationLine: 'underline', textAlign: 'center'}} onPress={() => Linking.openURL(to)}>{text}</Text>
  )
}

export default function App() {
  return (
    <LinearGradient
      style={styles.container}
      colors={['#0F2027', '#203A43', '#2C5364']}
      start={{x: 0, y:0.75}}
      end={{x: 1, y: 0.25}}
    >
      <View style={{padding: 25, backgroundColor: 'white', borderRadius: 5, borderColor: '#bababa', maxWidth: 600, alignItems: 'center', marginHorizontal: 15, paddingHorizontal: 50}}>
        <Text style={{fontWeight: "bold", fontSize: 20, marginBottom: 15}}>The Fadhelflix Network</Text>

        <View style={{gap: 10}}>
          <Link text={"Primary Server (Jellyfin)"} to={"https://media.fadhelflix.com"} />

          <Link text={"Download Manager"} to={"https://manage.fadhelflix.com"} />
        </View>

        {/*
        <Text style={{fontWeight: "bold", marginTop: 20, marginBottom: 10}}>Other Hosts</Text>

        <View style={{flexDirection: 'row', gap: 15}}>
          <Link text={"Ebooks"} to={"https://ebooks.fadhelflix.com"} />
          <Link text={"Audiobooks"} to={"https://audiobooks.fadhelflix.com"} />
        </View>
        */}
        <Text style={{fontWeight: "bold", marginTop: 20, marginBottom: 10}}>Supported Clients (General)</Text>

        <View style={{flexDirection: 'row', gap: 15}}>
          <Link text={"Jellyfin for Desktop"} to={"https://github.com/jellyfin/jellyfin-media-player/releases"} />
          <Link text={"Jellyfin for iOS"} to={"https://apps.apple.com/us/app/jellyfin-mobile/id1480192618"} />
          <Link text={"Jellyfin for Android"} to={"https://play.google.com/store/apps/details?id=org.jellyfin.mobile&hl=en&gl=US&pli=1"} />
          <Link text={"Swiftfin"} to={"https://apps.apple.com/us/app/swiftfin/id1604098728"} />
        </View>

        <Text style={{fontWeight: "bold", marginTop: 20, marginBottom: 10}}>Supported Clients (Music)</Text>

        <View style={{flexDirection: 'row', gap: 15}}>
          <Link text={"Finamp for iOS"} to={"https://apps.apple.com/us/app/finamp/id1574922594"} />
          <Link text={"Finamp for Android"} to={"https://play.google.com/store/apps/details?id=com.unicornsonlsd.finamp&hl=en_US"} />
        </View>
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
